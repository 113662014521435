@import "colors";

html,
body {
  font-family: "Source Code Pro", monospace;
  margin: 0;
  padding: 0;
}

.page {
  background: $dm-bg0-hard;
  color: $dm-fg1;

  a {
    color: $dm-light-purple;
    text-decoration: none;
  }
  
  a:hover {
    color: $dm-dark-purple;
  }
}

.page--light {
  background: $lm-bg0-hard;
  color: $lm-fg1;

  a {
    color: $lm-dark-purple;
    text-decoration: none;
  }
  
  a:hover {
    color: $lm-light-purple;
  }
}

.page--error {
  background: $dm-bg0-hard;
  color: $dm-light-red;
}

.container {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}


.heading,
.content {
  margin: 0;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.2);
  font-weight: 300;
}

.heading {
  font-size: 10rem;
}

.content {
  font-size: 1.5rem;
}

.logo {
  position: fixed;
  right: 1rem;
  bottom: 1rem;
  font-size: 2rem;
  font-weight: 300;
  opacity: 0.5;
  cursor: pointer;
}

.powered {
  position: fixed;
  left: 1rem;
  bottom: 1rem;
  font-size: 0.75rem;
  font-weight: 300;
  opacity: 0.5;
}

::selection {
  background: $dm-light-blue;
  color: $dm-fg1;
}
::-moz-selection {
  background: $dm-light-blue;
  color: $dm-fg1;
}
