// ----------------------------------------------------------------------------
// Dark Mode
// ----------------------------------------------------------------------------

// Dark Background
$dm-bg0: #282828;
$dm-bg0-hard: #1d2021;
$dm-bg0-soft: #32302f;
$dm-bg1: #3c3836;
$dm-bg2: #504945;
$dm-bg3: #665c54;
$dm-bg4: #7c6f64;

// Dark Foreground
$dm-fg0: #fbf1c7;
$dm-fg1: #ebdbb2;
$dm-fg2: #d5c4a1;
$dm-fg3: #bdae93;
$dm-fg4: #a89984;

// Dark Colors
$dm-dark-red: #cc241d;
$dm-dark-green: #98971a;
$dm-dark-yellow: #d79921;
$dm-dark-blue: #458588;
$dm-dark-purple: #b16286;
$dm-dark-aqua: #689d6a;
$dm-dark-orange: #d65d0e;
$dm-dark-gray: #928374;

$dm-light-red: #fb4934;
$dm-light-green: #b8bb26;
$dm-light-yellow: #fabd2f;
$dm-light-blue: #83a598;
$dm-light-purple: #d3869b;
$dm-light-aqua: #8ec07c;
$dm-light-orange: #f38019;
$dm-light-gray: #a89984;

// ----------------------------------------------------------------------------
// Light Mode
// ----------------------------------------------------------------------------

// Light Background
$lm-bg0: #fbf1c7;
$lm-bg0-hard: #f9f5d7;
$lm-bg0-soft: #f2e5bc;
$lm-bg1: #ebdbb2;
$lm-bg2: #d5c4a1;
$lm-bg3: #bdae93;
$lm-bg4: #a89984;

// Light Foreground
$lm-fg0: #282828;
$lm-fg1: #3c3836;
$lm-fg2: #504945;
$lm-fg3: #665c54;
$lm-fg4: #7c6f64;

// Light Colors
$lm-dark-red: #cc241d;
$lm-dark-green: #98971a;
$lm-dark-yellow: #d79921;
$lm-dark-blue: #458588;
$lm-dark-purple: #b16286;
$lm-dark-aqua: #689d6a;
$lm-dark-orange: #d65d0e;
$lm-dark-gray: #928374;

$lm-light-red: #9d0006;
$lm-light-green: #79740e;
$lm-light-yellow: #b57614;
$lm-light-blue: #076678;
$lm-light-purple: #8f3f71;
$lm-light-aqua: #427b58;
$lm-light-orange: #af3a03;
$lm-light-gray: #7c6f64;
